//remove annoying line on the search
.header-search #siteSearch {
  border-width: 0;
}

//add line to menu
// ul.topLevel {
//   border-top: solid #1e89d6 1px;
// }

//add line to intro
.homeIntro {
  h3 {
    border-left: 20px solid $brand-secondary;
    padding-left: 2rem;
  }
}

//Remove margin from impact
.homefeaturecategory-homeboximpactstats {
  table {
    margin: 0rem auto;
  }

  //   position: relative;
  //   &:before {
  //     content: "";
  //     background-image: url(../assets/heart.svg);
  //     background-repeat: no-repeat;
  //     display: block;
  //     height: 65px;
  //     width: 65px;
  //     position: absolute;
  //     right: 30px;
  //     background-size: 100%;
  //     background-position: 0px 0px;
  //     top: 30px;
  //   }
}

//remove padding from impact wrapper

.homefeaturecategory-homeboximpactstats .homeImpactWrapper {
  padding: 0px;
}

//style impact sections

.homefeaturecategory-homeboximpactstats {
  tr {
    margin: 0;
    padding: 5rem 1rem;
  }

  tr:first-child {
    background-color: darken($brand-primary, 5%);
  }
  tr:nth-child(2) {
    background-color: $brand-primary;
  }
  tr:nth-child(3) {
    background-color: lighten($brand-primary, 5%);
  }
}

//lines on feed titles

.homeFeed .feedsTitle {
  border-left: 10px solid $brand-secondary;
  padding-left: 2rem;
  position: relative;
  left: 15px;
}

//footer

// Footer

.footerBox {
  h3 {
    font-size: 1.5em;
  }

  .charityNumber {
    font-size: 0.875em;
  }
}

.footerBox.footerBox2 {
  padding-left: 3em;
  h3 {
    margin-bottom: $spacer * 1.5;
  }
}

.footerBox.footerBox3 {
  h3 {
    margin-bottom: $spacer * 1.5;
  }
}

.footerBox.footerBox4 {
  border-top: 1px solid rgba(white, 0.3);
  font-size: 12px;
  display: flex;
  flex-direction: row;
  img {
    padding: 25px 0;
    object-fit: contain;
    align-self: flex-start;
  }
  .footertext {
    flex: 1 1 auto;
    padding: 20px 40px;
  }
}
@media (max-width: map-get($breakpoints, sm)) {
  .footerBox.footerBox2 {
    padding-left: 0em;
  }

  .footerBox.footerBox4 {
    flex-direction: column;
    .footertext {
      flex: unset;
      padding: 20px 0px;
    }
  }
}

// add heart shape to donate
.cta-button.donate::after,
.button.donate::after,
button.donate::after {
  content: "";
  background-image: url(../assets/heart.svg);
  height: 20px;
  width: 30px;
  background-size: 20px 20px;
  background-repeat: no-repeat;
  /* padding-right: 10px; */
  background-position: 8px 1px;
}

@media (max-width: map-get($breakpoints, md)) {
  .menuMain .mainCallToAction .socialIcons a:before {
    color: $brand-primary;
  }
}



// Footer Location
.footerBox.FooterLocations {
  display: flex;
  max-width: $container-max-width;
  flex-wrap: wrap;
  margin: 0 auto;
  padding: 1rem $site-bleed;
  .footerBoxLocation {
    position: relative;
    color: $white;
    font-size: $font-size-small;
    display: block;
    width: 30%;
    margin-right: 5%;
    h5 { margin-bottom: 1rem; }
    h5, a { color: $white; }
    a.cta-button { background-color: $brand-secondary; }
    a.cta-button:hover { background-color: darken($brand-secondary, 10%); }
    &:last-of-type { margin-right: 0; }

    @media (max-width: 768px) {
      width: 100%;
      margin: 0 0 2rem 0;
    }
  }
}

// JK - overwrite nav social icon colour behaviour
@media (max-width: $header-social-icons-breakpoint) {
  .menuMain .mainCallToAction .socialIcons a:before {
    color: $brand-primary;
  }
}

// Page no sidebar (Catgeory)
body.PostCategory_find-a-neighbourhood-house,
body.PostCategory_page-no-sidebar {
  .post .contentBlock {
    // display: block;
    .postAside {
      display: none;
    }
  }
}

// Carousel mobile fix
@media (max-width: map-get($map: $breakpoints, $key: sm)) {
  .carouselSlideHeading {
    font-size: 1.5rem;
  }
}