// $platform-name: 'clientname';
// $platform-name: 'moorditchgurlonggaassocinc';

//
// Environment
//

//
// Config values
//
$platform-name: "moorditchgurlonggaassocinc";
$assets-path: "../assets/";

//
// Colours
//
$brand-primary: #192645;
$brand-secondary: #534ea0;
$grey: #58595b;
$donate-colour: $brand-secondary;
$bg: #ece9e9;
// Create greyscale

// Utility colours

//
// Layout
//

// Breakpoints

// Spacers
$spacer: 1rem;
$spacer-y: $spacer;
$spacer-x: $spacer;
$site-bleed: 20px;
$container-max-width: 1230px;
//
// Misc
//
$body-colour: $grey;
//
// Transitions
//

//
// Logo
//
$logo: "logo.svg";
$logo-width: 215px;
$logo-height: 120px;
// $logo-absolute: true;
//
// Typography
//
$font-open-sans: "Montserrat", sans-serif;
$font-family-base: $font-open-sans;
$headings-font-family: "Montserrat", sans-serif;
$headings-colour: #58595b;
$font-weight-bold: 900;

// Links

// Icon font

//
// Buttons
//
$border-radius: 3px;
$btn-border-radius: $border-radius;
$btn-padding-x: 1em;
$btn-padding-y: 0.75em;
$btn-font-family: $font-open-sans;
$btn-font-weight: $font-weight-bold;
// Donate button
$donate-btn-colour: white;
//
// Social icons
// Documentation: https://fresco-docs.netlify.com/#/header/social-icons/
//
$social-icons-border-radius: 100px;
$social-icons-width: 30px;
$social-icons-height: 30px;
$social-icons-font-size: 1rem;

$social-icons-background-colour: white;
$social-icons-colour: $brand-primary;
// Social network colours - use these by changing $social-icons-use-brand-colours to true

// Header specific

// Footer specific social icons
$social-icons-footer-background-colour: white;
//
// Share this page
//

//
// Form elements
//

// Field groups

//
// Tables
//

//
// Card
//
$card-border-radius: $border-radius;
$card-heading-colour: #58595b;
$card-hover-image-opacity: 1;
$card-hover-image-scale: 1.1;
$card-details-background-colour: $bg;
$card-hover-details-background-colour: $brand-primary;
$card-hover-heading-colour: white;
$card-border: 0;

// Card text overlay

// Card text over

// Card side by side

// Card hover state

// Event cards

//
// Menu admin
//
$menu-admin-enabled: false;
//
// Header
//
$header-background-colour: $brand-primary;
// Header content

$header-content-margin-bottom: 0rem;
$header-content-margin-top: 0;
// Tagline

// Main call to action
$cta-btn-gap: 20px;
$cta-btn-background-colour: white;
$cta-btn-colour: $brand-primary;
// Search
$header-search-margin-right: 5px;

// Search - input
$header-search-input-max-width: 0;
$header-search-input-margin-right: 0;
$header-search-input-padding: 0px 0px;
$header-search-input-border-colour: #fff;
$header-search-input-margin-right: 0px;

// Search - button
$header-search-button-background-colour: white;
$header-search-button-icon-colour: $brand-primary;
$header-search-button-border-radius: 100px;
$header-search-button-width: 30px;
$header-search-button-height: 30px;
// Search when in nav

// Social icons

//
// Sticky header
//

//
// Navigation
//
$nav-top-level-item-padding: 15px 20px;
$nav-background-colour: $brand-secondary;
$nav-normal-align-items: center;
// Top level items
$nav-top-level-item-colour: text-contrast($nav-background-colour);
$nav-top-level-item-font-weight: 600;
$nav-top-level-item-font-family: $headings-font-family;
// Submenus

// Burger button

// Nav normal
$nav-normal-margin-bottom: 0rem;
$nav-normal-max-width: 100%;
//
// Specifically when no banner present and you have gone for $header-absolute: true
//

//
// Carousel
//
$carousel-heading-font-size: 3rem;
$carousel-max-width: 100%;
$carousel-contents-max-width: $container-max-width;
$carousel-image-overlay: linear-gradient(
  to right,
  rgba(0, 0, 0, 0.7) 0%,
  rgba(0, 0, 0, 0) 50%
);
$carousel-details-position-y: center;
$carousel-details-background-colour: transparent;
$carousel-details-box-shadow: none;
$carousel-details-max-width: 600px;
$carousel-heading-colour: #fff;
$carousel-summary-colour: #fff;

$carousel-controls-position-y: center;
$carousel-controls-position-x: split;
$carousel-controls-margin-x: $site-bleed;
$carousel-controls-icon-colour: #fff;
$carousel-read-more-background-colour: $brand-secondary;
// Carousel controls (left-right buttons)
$carousel-controls-button-background-colour: transparent;
// Carousel buttons (dots or tabs)

// Carousel buttons dots

// Carousel buttons tabs

// Carousel video play button (before breakpoint, when carousel details present)

// Carousel scroll button

// Carousel after breakpoint

// Banner min-height under sm breakpoint - helps to keep some vertical height at narrow viewports, especially for letter-box aspect ratios

//
// Home intro
//
$home-intro-background-colour: white;
$home-intro-font-size: 1.25rem;
$home-intro-padding-y: $spacer * 1;
$home-intro-margin-top: 4rem;
$home-intro-text-align: left;
//
// Home features
//
$home-features-padding-top: $spacer * 4;
//
// Impact stats
//
$impact-stats-padding-y: 0;
$impact-stats-content-max-width: 100%;
$impact-stats-background-colour: transparent;
$impact-stats-colour: white;
// Heading

// Individual stat

// Figure
$impact-stats-figure-font-family: $font-open-sans;
// Summary

//
// Home feeds
//
$home-feeds-background-colour: white;
// Feeds title

// Feed item

// Event feed items
$feed-item-event-start-date-enabled: false;
$feed-item-event-location-enabled: false;
// Twitter feed

//
// Footer
//
$footer-boxes-count: 4;
$footer-prefab: 5;
$footer-padding-y: $spacer * 3;
// $footer-background-colour: rgba(#eceeef, .3);
$footer-background-colour: $brand-primary;
$footer-colour: white;
$footer-link-colour: white;
$footer-headings-font-size: 1.5em;
// Footer admin links

$footer-admin-login-link-enabled: false;
$footer-admin-basket-link-enabled: false;


// Newsletter
$newsletter-button-colour: white;
$newsletter-heading-text-align: left;
$newsletter-heading-font-size: 1.5em;
$newsletter-use-placeholders: true;
// Newsletter hero - full width form above rest of footer

//
// Context container
//

// Donors list

//
// Posts
//

$link-colour: $brand-secondary;

//
// Header text (container for breadcrumb and page title)
//

//
// Page title
//
$page-title-colour: $brand-secondary;
//
// Sidebar
//
$sidebar-mobile-layout: below;
// Associated lists

//
// Listing
//

// Listed post settings

//
// Breadcrumb
//

//
// Blockquote
//

//
// Fundraising
//

// Find a fundraiser widget

// Top fundraisers

//
// Donation form
//

// Donation amounts

//
// Quick giving panel
//

//
// Home quick giving panel
//

// Adjacent homepage quick giving

// Overlay homepage quick giving

//
// Totalisers
//

//
// Comments
//

//
// Blog details
//

//
// FAQs
//

//
// Shop
//

// Departments list

// Product-price

// Product post

//
// Subsite
//

// Subsite logo

// Subsite nav

//
// Cookie consent
//
